<template>
  <div class="myInfo">
    <div class="inner-1000">
      <div>
        <div class="m-header">个人资料</div>
        <el-form class="m-form" ref="form" :model="user" label-width="100px">
          <el-form-item label="头像">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :multiple="false"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :headers="headers"
            >
              <img v-if="user.avatar" :src="user.avatar" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="text">建议尺寸：160px * 160px</div>
          </el-form-item>
          <el-form-item label="教师风采">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :multiple="false"
              :show-file-list="false"
              :on-success="handleDetailSuccess"
              :before-upload="beforeAvatarUpload"
              :headers="headers"
            >
              <img v-if="user.detailUrl" :src="user.detailUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="text">建议尺寸：600px * 255px</div>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input style="width: 250px;" v-model="user.name"></el-input>
          </el-form-item>
          <!--
		  <el-form-item label="昵称">
            <el-input style="width: 250px;" v-model="user.nickName"></el-input>
          </el-form-item>
		  -->
          <el-form-item label="性别">
            <el-radio-group v-model="user.sex">
              <el-radio label="男" value="男"></el-radio>
              <el-radio label="女" value="女"></el-radio>
            </el-radio-group>
          </el-form-item>
		  <el-form-item label="一级学科">
		    <el-select
		      v-model="user.firstSubjectId"
		      placeholder="请选择"
		      @change="onChooseFirstSub"
		    >
		      <el-option
		        v-for="item in firstSubject"
		        :key="item.id"
		        :label="item.name"
		        :value="item.id"
		      ></el-option>
		    </el-select>
		  </el-form-item>
		  <el-form-item label="二级学科">
		    <el-select v-model="user.secondSubjectId" placeholder="请选择" @change="onChooseSecSub">
		      <el-option
		        v-for="item in secondSubject"
		        :key="item.id"
		        :label="item.name"
		        :value="item.id"
		      ></el-option>
		    </el-select>
		  </el-form-item>
          <el-form-item label="手机号">
            <el-input style="width: 250px;" v-model="user.mobileNo" disabled></el-input>
          </el-form-item>
          
		  <el-form-item label="邮箱">
            <el-input style="width: 300px;" v-model="user.email"></el-input>
          </el-form-item>
          <!--
		  <el-form-item label="出生日期">
            <el-date-picker
              v-model="user.birthday"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="地区">
            <el-cascader
              style="width: 300px;"
              size="large"
              :options="options"
              v-model="selectedOptions"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="现住地">
            <el-input style="width: 300px;" v-model="user.address"></el-input>
          </el-form-item>
          <el-form-item label="最高学历">
            <el-select v-model="user.highEdu" placeholder="请选择">
              <el-option
                v-for="item in education"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
		  -->
		  <!--
          <el-form-item label="身份证号">
            <el-input style="width: 250px;" v-model="user.idCard" disabled></el-input>
          </el-form-item>
		  -->
          <el-form-item label="简介">
            <el-input style="width: 250px;" v-model="user.brief"></el-input>
          </el-form-item>
          <el-form-item label="详情">
            <quill-editor
              v-model="user.detail"
              ref="myQuillEditor"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            ></quill-editor>
          </el-form-item>
          <el-form-item label="标签1">
            <el-input style="width: 100px;" v-model="user.tag1"></el-input>
          </el-form-item>
          <el-form-item label="标签2">
            <el-input style="width: 100px;" v-model="user.tag2"></el-input>
          </el-form-item>
          
          <el-form-item><el-button class="sub-btn" @click="onSubmit">保存</el-button></el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data';
export default {
  name: '',
  data() {
    return {
      user: {
        detailUrl: ''
      },
      action: 'http://stu.100niu.com/api/biz/upload/file',
      headers: {
        Authorization: 'Bearer ' + this.$.data.get('Token')
      },
      options: regionData,
      selectedOptions: [],
      provinceId: '',
      cityId: '',
      areaId: '',
      eduConfig: {
        dzyx: '大专以下',
        dz: '大专',
        bk: '本科',
        yjs: '研究生',
        bsys: '博士及以上'
      },
      education: [
        { name: '大专以下', value: 'dzyx' },
        { name: '大专', value: 'dz' },
        { name: '本科', value: 'bk' },
        { name: '研究生', value: 'yjs' },
        { name: '博士及以上', value: 'bsys' }
      ],
      firstSubjectIndex: 0,
      secondSubjectIndex: 0,
      firstSubjectID: '',
      secondSubjectID: '',
      firstSubject: [],
      secondSubject: []
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  created() {
    // 获取个人信息
    this.getUserInfo();
    this.getFirstSubject();
  },
  mounted() {},
  watch: {},
  methods: {
    onEditorBlur(quill) {
      console.log('editor blur!', quill);
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill);
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text);
      this.content = html;
    },
    // 获取个人信息
    getUserInfo() {
      this.get('/biz/teacher/personal/getInfo', {}, e => {
        if (e.code === 200) {
			if(e.data.sex)e.data.sex=e.data.sex=='0'?'男':'女'
          this.user = e.data;
          this.getSecondSubject(this.user.firstSubjectId);
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 成功上传的回调
    handleAvatarSuccess(res, file) {
      console.log(res);
      // this.user.avatar = URL.createObjectURL(file.raw);
      if (res.code == 200) {
        this.user.avatar = res.data;
      } else {
        this.$.ui.toast(res.msg);
      }
    },
    // 成功上传的回调
    handleDetailSuccess(res, file) {
      console.log(res);
      if (res.code == 200) {
        this.user.detailUrl = res.data;
      } else {
        this.$.ui.toast(res.msg);
      }
    },
    // 判断上传文件是否为图片
    beforeAvatarUpload(file) {
      const isImage = file.type.includes('image');
      if (!isImage) {
        this.$.ui.toast('上传文件类型必须是图片');
      }
      return isImage;
    },
    handleChange(e) {
      console.log(e);
      var loc = '';
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]];
      }
      this.user.provinceId = e[0];
      this.user.cityId = e[1];
      this.user.regionId = e[2];
    },
    onSubmit() {
		if(!this.user.sex)return this.$.ui.toast('请选择性别！');
      this.post(
        '/biz/teacher/personal/setInfo',
        {
          address: this.user.address,
          avatar: this.user.avatar,
          birthday: this.user.birthday,
          brief: this.user.brief,
          detail: this.user.detail,
          email: this.user.email,
          highEdu: this.user.highEdu,
          idCard: this.user.idCard,
          name: this.user.name,
          nickName: this.user.nickName,
          regionId: this.user.regionId,
          firstSubjectId: this.user.firstSubjectId,
          secondSubjectId: this.user.secondSubjectId,
          subjectId: this.user.secondSubjectId || this.user.firstSubjectId,
          tag1: this.user.tag1,
          tag2: this.user.tag2,
          sex: this.user.sex=='男'?'0':(this.user.sex=='女'?'1':''),
          detailUrl: this.user.detailUrl
        },
        e => {
          if (e.code == 200) {
            this.$.ui.toast('修改成功');
            this.getUserInfo();
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 获取一级学科列表
    getFirstSubject() {
      this.get('/biz/subject/list', { pid: '' }, e => {
        if (e.code === 200) {
          // 将一级学科索引归零
          this.firstSubjectIndex = 0;
          this.firstSubject = e.data;
          // this.firstSubjectID = this.firstSubject[0].id;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 获取二级学科列表
    getSecondSubject(pid) {
      this.get('/biz/subject/list', { pid: pid }, e => {
        if (e.code === 200) {
          // 将二级学科索引归零
          this.secondSubjectIndex = 0;
          this.secondSubject = e.data;
          console.log(this.secondSubject);
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 更改一级学科下拉框时
    onChooseFirstSub(e) {
      console.log(e);
      this.user.firstSubjectId = e;
      this.user.subjectId = e;
      this.getSecondSubject(e);
    },
    onChooseSecSub(e) {
      this.user.subjectId = e;
      this.user.secondSubjectId = e;
    }
  },
  components: {}
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.ql-editor{
  min-height: 300px;
}
</style>
